* {
  box-sizing: border-box !important;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

/* CSS */
html, body {
  height: 100%;
}

body {
  background: url('/imagens/background-rir.jpg') center top no-repeat;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;
}

/* Screen reader only */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

/* Button styling */
.menu-toggle {
	display: inline-block;
	padding: .75em 15px;
	line-height: 1em;
	font-size: 1em;
	color: #fff;
}

.menu-toggle:hover,
.menu-toggle:focus {
	color: #fff;
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
	position: absolute;
	left: -200px;
	top: 0;
	height: 100%;
	overflow-y: scroll;
	overflow-x: visible;
	transition: left 0.3s ease,
		box-shadow 0.3s ease;
	z-index: 999;
	overflow: hidden;
}

.main-menu ul {
	list-style: none;
	margin: 0;
	padding: 2.5em 0 0;
	/* Hide shadow w/ -8px while 'closed' */
	-webkit-box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
	-moz-box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
	box-shadow: -8px 0 8px rgba(0, 0, 0, .5);
	min-height: 100%;
	width: 200px;
	background: #1a1a1a;
}

.main-menu a {
	display: block;
	padding: .75em 15px;
	line-height: 1em;
	font-size: 1em;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid #383838;
}

.main-menu li:first-child a {
	border-top: 1px solid #383838;
}

.main-menu a:hover,
.main-menu a:focus {
	background: #333;
	text-decoration: underline;
}

.main-menu .menu-close {
	position: absolute;
	right: 0;
	top: 0;
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 aria-expanded="true/false" will be for JavaScript
*/
.main-menu:target,
.main-menu[aria-expanded="true"] {
	left: 0;
	outline: none;
	-moz-box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
	-webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
	box-shadow: 3px 0 12px rgba(0, 0, 0, .25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
	z-index: 1001;
}

.main-menu ul {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.main-menu ul li {
	width: 100%
}

.main-menu:target ul,
.main-menu[aria-expanded="true"] ul {
	position: relative;
	z-index: 1000;
}

/* 
 We could us `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
.main-menu:target+.backdrop,
.main-menu[aria-expanded="true"]+.backdrop {
	position: absolute;
	display: block;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 998;
	background: #000;
	background: rgba(0, 0, 0, .85);
	cursor: default;
}

@supports (position: fixed) {

	.main-menu,
	.main-menu:target+.backdrop,
	.main-menu[aria-expanded="true"]+.backdrop {
		position: fixed;
	}
}

@media (min-width: 768px) {
	header {
		display: none;
	}
}